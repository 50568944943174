import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { formatPrice } from "../Utils/Utils";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {},
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    minHeight: 200,
    margin: theme.spacing(2, 2, 1, 2),
  },
  section2: {
    margin: theme.spacing(1),
    textAlign: "center",
  },
  section3: {
    margin: theme.spacing(2),
    textAlign: "center",
  },
  priceChip: {
    fontWeight: "bold",
    backgroundColor: theme.palette.background.white,
    color: theme.palette.primary.main,
    fontSize: "125%",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderWidth: "2px",
  },
  titleDecoration: {
    display: "inline",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: "2px",
  },
}));

const getCallToActionBtn = (courseType) => {
  if (courseType === "video") {
    return (
      <Button
        fullWidth={true}
        color={"primary"}
        variant={"contained"}
        startIcon={<PlayCircleOutlineIcon />}
      >
        Přehrát kurz
      </Button>
    );
  }
  return (
    <Button
      fullWidth={true}
      color={"primary"}
      variant={"contained"}
      startIcon={<PlaylistPlayIcon />}
    >
      Spustit test
    </Button>
  );
};

const getIconForType = (courseType, chipType) => {
  switch (courseType + "-" + chipType) {
    case "kurz-count": {
      return <VideoLibraryIcon />;
    }
    case "kurz-duration": {
      return <AccessTimeIcon />;
    }
    case "test-count": {
      return <AssignmentIcon />;
    }
    default: {
      return null;
    }
  }
};

const Courses = (props) => {
  const classes = useStyles();

  const { courses } = props;

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justify="center"
      alignItems="stretch"
    >
      {courses.map((course) => {
        return (
          <Grid item xs={12} sm={6} md={3} key={courses.indexOf(course)}>
            <Card className={classes.card}>
              <Link to={"/kurz/" + course.slug}>
                <CardActionArea>
                  <div className={classes.section1}>
                    <Grid container direction={"column"}>
                      <Grid item container alignItems={"baseline"}>
                        <Grid item xs>
                          <Typography
                            variant={"subtitle1"}
                            color={"textSecondary"}
                            gutterBottom
                          >
                            {course.category.title}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography color={"primary"} gutterBottom>
                            <strong>{formatPrice(course.price)}</strong>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          gutterBottom
                          variant="h5"
                          style={{ fontWeight: "bold" }}
                        >
                          {course.title}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography color="textSecondary" variant="body2">
                      {course.description}
                    </Typography>
                  </div>
                </CardActionArea>
              </Link>
              <Divider />
              <div className={classes.section3}>
                <Link to={"/kurz/" + course.slug}>
                  {getCallToActionBtn(course.courseType.code)}
                </Link>
              </div>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Courses;
